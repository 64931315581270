import { Select, Cascader } from "antd";
import { MARK, SPEECH_RATE } from "@/lib/constants";
import {
  CascaderLanguageProp,
  CascaderOptionsProps,
  SelectSpeakingSpeedProp,
  languageSelectProp,
} from "./prop";
import { FC, memo, useMemo } from "react";
import { useVideoStore } from "@/store";

// 传入一个带有value参数的函数，用于获取值
export const LanguageSelect = (props: languageSelectProp) => {
  return (
    <Select
      value={props?.value}
      defaultValue={props?.defaultValue}
      style={{ width: props.width || "100px" }}
      placeholder={props.placeholder || "选择人声"}
      onChange={props.onChange}
      dropdownRender={(menu) => <div>{menu}</div>}
      options={props.data.map((item) => ({
        label: item.label,
        value: item.value,
        disabled: item.disabled,
      }))}
    />
  );
};

export const SelectSpeakingSpeed = (props: SelectSpeakingSpeedProp) => {
  return (
    <LanguageSelect
      value={props?.value}
      defaultValue={props?.defaultValue}
      placeholder="选择语速"
      data={SPEECH_RATE}
      onChange={props.onChange}
    ></LanguageSelect>
  );
};

// 选择人声，级联
const displayRender = (labels: string[]) => labels[labels.length - 1];

export const CascaderLanguage: FC<CascaderLanguageProp> = memo((props) => {
  const [voice] = useVideoStore((store) => [store.voice]);

  const voiceOptions = useMemo(() => {
    const tempList: CascaderOptionsProps[] = [
      {
        label: "标注",
        value: 0,
        disabled: props.disabled,
        children: [],
      },
      {
        label: "不标注",
        value: 1,
        children: [],
      },
    ];

    voice.forEach((item) => {
      tempList[1].children?.push({
        label: item.comment,
        value: item.voice_id,
      });

      if (item.voice_id < 200) {
        tempList[0].children?.push({
          label: item.comment,
          value: item.voice_id,
        });
      }
    });
    return tempList;
  }, [props.disabled, voice]);

  const onChange = (valueList: (string | number)[] | undefined) => {
    props.onChange?.(valueList as number[] | undefined);
  };

  return (
    <Cascader
      style={{ width: 130 }}
      options={voiceOptions}
      displayRender={displayRender}
      value={props.value}
      onChange={onChange}
      placeholder="选择人声"
    />
  );
});
